@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --textgrey: #1e1e1e;
  --grey: #b3b3b3;
  --background-navy: #155264;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer components {
  .flex-center {
    @apply flex justify-center items-center;
  }

  .text-heading {
    @apply text-textgrey text-[16px] font-medium leading-normal;
  }

  .language-active {
    @apply text-textblack;
  }

  .text-days {
    @apply text-white text-base leading-none font-normal;
  }
}

.sign-container,
.language-container {
  box-shadow: -8px 8px 16px rgba(217, 217, 217, 0.2),
    8px -8px 16px rgba(217, 217, 217, 0.2),
    -8px -8px 16px rgba(255, 255, 255, 0.9),
    8px 8px 20px rgba(217, 217, 217, 0.9),
    inset 1px 1px 2px rgba(255, 255, 255, 0.3),
    inset -1px -1px 2px rgba(217, 217, 217, 0.5);
}

.date-button-container,
.help-info-container {
  box-shadow: -8px 8px 16px rgba(199, 199, 199, 0.2),
    8px -8px 16px rgba(199, 199, 199, 0.2),
    -8px -8px 16px rgba(255, 255, 255, 0.9),
    8px 8px 20px rgba(199, 199, 199, 0.9),
    inset 1px 1px 2px rgba(255, 255, 255, 0.3),
    inset -1px -1px 2px rgba(199, 199, 199, 0.5);
}

.help-icon-container {
  box-shadow: 1px 1px 2px rgba(239, 239, 239, 0.3),
    -1px -1px 2px rgba(195, 195, 195, 0.5),
    inset -5px 5px 10px rgba(195, 195, 195, 0.2),
    inset 5px -5px 10px rgba(195, 195, 195, 0.2),
    inset -5px -5px 10px rgba(239, 239, 239, 0.9),
    inset 5px 5px 13px rgba(195, 195, 195, 0.9);
}

.days-container {
  background: linear-gradient(135deg, #00772c 0%, #006b28 100%);
  box-shadow: -1px 1px 2px rgba(0, 70, 26, 0.2),
    1px -1px 2px rgba(0, 70, 26, 0.2), -1px -1px 2px rgba(0, 156, 58, 0.9),
    1px 1px 3px rgba(0, 70, 26, 0.9), inset 1px 1px 2px rgba(0, 156, 58, 0.3),
    inset -1px -1px 2px rgba(0, 70, 26, 0.5);
}

.rdp {
  margin: unset !important;
  margin-bottom: 30px !important;
}

.rdp-day {
  color: var(--textgrey) !important;
}

.rdp-head_cell {
  color: var(--grey);
  height: unset !important;
}

.rdp-day,
.rdp-head_cell {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.rdp-day,
.rdp-cell {
  max-width: unset !important;
  width: 48px !important;
  height: 48px !important;
}

.rdp-day_selected {
  color: white !important;
  background: var(--background-navy) !important;
  box-shadow: -1px -1px 2px rgba(26, 103, 126, 0.3),
    1px 1px 2px rgba(16, 61, 74, 0.5), inset 1px -1px 2px rgba(16, 61, 74, 0.2),
    inset -1px 1px 2px rgba(16, 61, 74, 0.2),
    inset 1px 1px 2px rgba(26, 103, 126, 0.9),
    inset -1px -1px 3px rgba(16, 61, 74, 0.9);
}

.rdp-table {
  max-width: unset !important;
}
